import { NavLink, To } from 'react-router-dom'
import { NavTabsStyled } from './NavTabs.styles'

export type NavTabItem = {
  label: string
  to: To
}

type NavTabsProps = {
  tabs: ReadonlyArray<NavTabItem>
}

export const NavTabs = ({ tabs }: NavTabsProps): React.ReactElement => {
  return (
    <NavTabsStyled>
      {tabs.map((item) => (
        <NavLink key={item.label} to={item.to}>
          {item.label}
        </NavLink>
      ))}
    </NavTabsStyled>
  )
}
