import styled, { css } from 'styled-components'

export const NavTabsStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spacing(3)};
    border-bottom: 1px solid ${theme.colors.grey400};

    a {
      text-decoration: none;
      color: inherit;
      padding-bottom: ${theme.spacing(1)};
      font-size: ${theme.global.typography.size['size-18']};

      &.active {
        border-bottom: 2px solid ${theme.colors.black};
      }
    }
  `
)
